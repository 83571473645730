import { Layout } from 'antd'

const Footer = () => {
  return (
    <Layout.Footer style={{ textAlign: 'center' }}>
      Copyright © CP Axtra Public Company Limited {new Date().getFullYear()}. All Rights Reserved.
    </Layout.Footer>
  )
}

export default Footer
